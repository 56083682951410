import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, useRef } from 'react';
import { SoundButton } from '../../../../components/SoundButton';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';

interface SelectAnswerModalProps {
  isOpen?: boolean;
  onSubmit?: () => Promise<void>;
  onClose?: () => void;
}

export const SelectAnswerModal: FC<SelectAnswerModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const theme = useThemeContext();
  const inProgress = useRef(false);
  const handleClose = () => {
    if (inProgress.current === false) {
      onClose?.();
    }
  };
  const handleSubmit = async () => {
    if (inProgress.current === true) {
      return;
    }
    inProgress.current = true;
    try {
      await onSubmit?.();
      inProgress.current = false;
      handleClose();
    } catch (e) {
      inProgress.current = false;
    }
  };
  const borderColor = theme.pc_popup_border;
  const submitColor = theme.pc_popup_submit_button;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-40'></div>
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full justify-center items-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div
                style={{
                  maxWidth: 1280,
                  width: 1280,
                  maxHeight: 800,
                  height: 800,
                  padding: '227px 255px 161px',
                }}
              >
                <Dialog.Panel
                  className='w-full h-full bg-white flex flex-col justify-center items-center border-[5px] rounded-2xl'
                  style={{ padding: '60px 123px', borderColor: borderColor }}
                >
                  <div className='w-full flex-1 flex items-center'>
                    <p
                      className='whitespace-pre-line text-p4 text-darkBlueGray font-medium text-center'
                      style={{ lineHeight: 1.57 }}
                    >
                      선택하신 보기로 정답 제출 하시겠습니까?
                      <br />
                      정답 제출 이후 변경 불가능합니다
                    </p>
                  </div>
                  <div className='text-center space-x-6'>
                    <SoundButton
                      className='p-4 text-p4  text-darkBlueGray bg-veryLightPink rounded-2xl'
                      style={{ width: 190 }}
                      onClick={handleClose}
                      type='button'
                      playSound={shouldPlaySound}
                    >
                      취소
                    </SoundButton>
                    <SoundButton
                      className='p-4 text-p4  text-darkBlueGray rounded-2xl'
                      style={{ width: 190, backgroundColor: submitColor }}
                      onClick={handleSubmit}
                      type='button'
                      playSound={shouldPlaySound}
                    >
                      제출
                    </SoundButton>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
