import { FC, useMemo, useRef, useState } from 'react';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';
import { getStockInfo } from '../../../../../utils/getStockInfo';
import classNames from 'classnames';
import { getTotalCash } from '../../../../../utils/getTotalCash';
import { HttpError } from '../../../../../apis/http-error';
import { GameApis, StockDealRecepit } from '../../../../../apis/game.apis';
import { StockRecepitModal } from '../StockRecepitModal';
import { getFormattedDiff } from '../../../../../utils/getFormattedDiff';
import { BackButton } from '../BackButton';
import { SoundButton } from '../../../../../components/SoundButton';

interface BuyStockProps {
  mode: string;
  onBack?: () => void;
}

function formatNumber(str: string) {
  const formatted =
    (str.startsWith('-') ? '-' : '') + str.replace(/[^0-9]/g, '');
  return formatted;
}

export const BuyStock: FC<BuyStockProps> = ({ onBack, mode }) => {
  const [, id = ''] = mode.split('::');
  const { session, userId = '' } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정

  const max_buyed_limit_percent = session.balance.round_2_stock_max_buy ?? 100;
  const { prev_price, price, name } = getStockInfo(id, session.stocks);
  const diff = price - prev_price;
  const rate = (diff * 100) / prev_price;
  const { current_cash } = getTotalCash(userId, session);
  const [input, setInput] = useState('0');
  const stockCount = useMemo(() => {
    const _val = +input;
    if (isNaN(_val)) {
      return 0;
    }
    return _val;
  }, [input]);
  const totalPrice = price * stockCount;
  const restCash = current_cash - totalPrice;
  const [recepitData, setRecepitData] = useState<{
    data: StockDealRecepit;
    isOpen: boolean;
  }>({
    data: {
      cash: {
        current: 0,
        diff: 0,
      },
      stock: {
        name: name,
        amount: 0,
        diff: 0,
      },
    },
    isOpen: false,
  });
  const increasePrice = Math.floor(
    (session.balance.stock_deal_effect_price / 100) * price
  );
  const usableCash = Math.floor(
    current_cash * ((max_buyed_limit_percent ?? 100) / 100)
  );
  const maxBuyableCount = isNaN(Math.floor(usableCash / price))
    ? 0
    : Math.floor(usableCash / price);
  const handleChangeInput = (val: string | number) => {
    if (isFinite(+val) === false) {
      val = 2000000;
    }
    const value = formatNumber(val.toString());

    const numVal = +value;
    if (isNaN(numVal)) {
      setInput('0');
    } else {
      const buyableAmount = maxBuyableCount;
      const next = Math.max(Math.min(buyableAmount, numVal), 0);
      setInput(next.toString());
    }
  };
  const handleIncrease = () => {
    const numVal = +input;
    const num = isNaN(numVal) ? 0 : numVal;
    handleChangeInput(num + 1);
  };
  const handleDecrease = () => {
    const numVal = +input;
    const num = isNaN(numVal) ? 0 : numVal;
    handleChangeInput(num - 1);
  };
  const handleMax = () => {
    const usable_cash = Math.floor(
      current_cash * (max_buyed_limit_percent / 100)
    );
    const buyableAmount = Math.floor(usable_cash / price);
    handleChangeInput(buyableAmount);
  };
  const inProcess = useRef(false);
  const handleBuy = async () => {
    if (inProcess.current === true) {
      return;
    }
    inProcess.current = true;
    try {
      const res = await GameApis.buyStock({
        userId,
        amount: stockCount,
        session_id: session._id,
        stockId: id,
      });
      setRecepitData({ data: res, isOpen: true });
      setInput('0');
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    } finally {
      inProcess.current = false;
    }
  };
  return (
    <div
      className='flex-1 overflow-y-auto'
      style={{ padding: '16px 20px 20px' }}
    >
      <StockRecepitModal
        {...recepitData}
        onClose={() => setRecepitData((prev) => ({ ...prev, isOpen: false }))}
      />
      <div className='mb-2'>
        <BackButton onClick={onBack} />
      </div>
      <div className='bg-[#f5f5f5] border border-veryLightPink p-4 rounded-xl'>
        <div className='flex justify-between'>
          <p className='font-medium text-m6 text-darkBlueGray'>
            {name}팀 현재 주가
          </p>
          <div>
            <p
              className='font-medium text-m6 text-right  text-darkBlueGray'
              style={{ marginBottom: 5 }}
            >
              <strong>{price.toLocaleString('ko-KR')}</strong> 원
            </p>
            <p
              className={classNames('text-m7 text-right ', {
                'text-coral': diff >= 0,
                'text-dodgerBlue': diff < 0,
              })}
            >
              {getFormattedDiff({
                price: diff,
                rate,
                prefixSpace: false,
              })}
            </p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 15 / 2 }}>
        <div className='bg-coral p-4 pb-3 rounded-t-xl'>
          <p className='text-m6 font-medium text-white text-center '>
            매수(사기)
          </p>
        </div>
        <div className='bg-[#ffebeb] border border-t-0 border-veryLightPink p-4 pb-3 rounded-b-xl'>
          <div className='flex justify-between items-center'>
            <p className='text-m6 text-themBlack font-medium pt-1'>구매 주수</p>
            <SoundButton
              className='text-red bg-[#ffd3d3] text-m6 font-medium  pt-1.5 pb-0.5 px-[15px] rounded-xl disabled:bg-browngrey disabled:text-[#707070]'
              type='button'
              style={{
                boxShadow: '0 2px 3px 0 rgba(255, 67, 67, 0.3)',
              }}
              onClick={handleMax}
              disabled={stockCount === maxBuyableCount}
              playSound={shouldPlaySound}
            >
              최대
            </SoundButton>
          </div>
          <div className='flex mt-3 mb-6'>
            <SoundButton
              type='button'
              className='bg-red flex justify-center items-center rounded-l-1.5xl disabled:bg-browngrey'
              style={{ width: 90 / 2, height: 90 / 2 }}
              onClick={handleDecrease}
              disabled={stockCount === 0}
              playSound={shouldPlaySound}
            >
              <span className='ic-minus'></span>
            </SoundButton>
            <div
              className='flex-1 bg-white text-right flex justify-end items-end'
              style={{ padding: 9 }}
            >
              <input
                type='text'
                className='w-full text-right text-m4  outline-none text-themeBlack font-medium'
                value={input}
                onChange={(e) => handleChangeInput(e.target.value)}
                placeholder='0'
              />
              <span className='text-m6 font-medium '>주</span>
            </div>
            <SoundButton
              className='bg-red flex justify-center items-center rounded-r-1.5xl disabled:bg-browngrey'
              style={{ width: 90 / 2, height: 90 / 2 }}
              type='button'
              onClick={handleIncrease}
              disabled={stockCount === maxBuyableCount}
              playSound={shouldPlaySound}
            >
              <span className='ic-plus'></span>
            </SoundButton>
          </div>
          <div className='flex justify-between'>
            <p className='text-m6 text-themBlack font-medium leading-tight'>
              사용 현금
            </p>
            <p className='text-m4 text-themBlack font-medium leading-tight'>
              {totalPrice.toLocaleString('ko-KR')}
              <span className='text-m6'> 원</span>
            </p>
          </div>
          <div className='flex justify-between mt-3'>
            <p className='text-m6 text-themBlack font-medium leading-tight'>
              남은 현금
            </p>
            <p className='text-m4 text-themBlack font-medium leading-tight'>
              {restCash.toLocaleString('ko-KR')}
              <span className='text-m6'> 원</span>
            </p>
          </div>
          <p className='text-browngrey text-center text-m7 font-medium leading-snug mt-4'>
            주가 상승 시 매수 인원 1명 당<br />
            추가로 {increasePrice.toLocaleString('ko-KR')}원 상승합니다.
          </p>
        </div>
      </div>
      <SoundButton
        className='red-btn-mo w-full mt-2'
        onClick={handleBuy}
        disabled={stockCount === 0}
        playSound={shouldPlaySound}
      >
        매수
      </SoundButton>
    </div>
  );
};
