import { Dispatch, SetStateAction, createContext } from 'react';
import { PlaySessionType } from '../../types/PlaySession.type';

export const PlaySessionContext = createContext<{
  is_effect_sound: boolean;
  round_2_start: any;
  session: PlaySessionType;
  teamCode: string;
  userId?: string;
} | null>(null);

export const PlaySessionDispatch = createContext<{
  setPlaySession: (next: PlaySessionType, createdAt: string) => void;
  dispatch: Dispatch<
    SetStateAction<{
      session: PlaySessionType;
      teamCode: string;
      userId?: string | undefined;
    }>
  >;
} | null>(null);
