import { FC } from 'react';
import { FreeDiscussionIconPng } from '../../../../assets/images';
import { PlaySessionType } from '../../../../types/PlaySession.type';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';

interface FreeDiscussionProps {}

function getMySelectData(
  userId: string,
  turnInfos: PlaySessionType['turn_info']
) {
  const turnInfo = turnInfos[turnInfos.length - 1];
  const userChoice = turnInfo.users_choice.find((v) => v._id === userId);
  return userChoice?.choice ?? '';
}

const Icon: FC<{ select: string }> = ({ select }) => {
  const theme = useThemeContext();
  const iconColor = theme.my_choice_color;
  return (
    <span
      className='block bg-darkBlueGray rounded-full'
      style={{
        width: 49 / 2,
        height: 49 / 2,
        paddingTop: 1,
        backgroundColor: iconColor,
      }}
    >
      <span className='text-white inline-block font-bold text-m8 text-center w-full'>
        {select}
      </span>
    </span>
  );
};

export const FreeDiscussion: FC<FreeDiscussionProps> = () => {
  const { session, userId = '' } = usePlaySessionContext();
  const selectData = getMySelectData(userId, session.turn_info);
  return (
    <div
      className='flex-1 overflow-y-auto'
      style={{ padding: '60px 20px 20px' }}
    >
      {selectData !== '' ? (
        <div
          className='flex justify-center items-center'
          style={{ marginBottom: 96 / 2 }}
        >
          <p className='pt-1 mr-1.5 font-medium text-darkBlueGray text-m6 leading-none'>
            나의 선택 :
          </p>
          <Icon select={selectData} />
        </div>
      ) : null}
      <p className='text-m5 text-darkBlueGray font-medium text-center'>
        <strong>자유롭게 토론</strong>해 주세요
      </p>
      <img
        src={FreeDiscussionIconPng}
        alt='자유 토론'
        className='mx-auto'
        style={{ width: 315 / 2, height: 272 / 2, marginTop: 105 / 2 }}
      ></img>
    </div>
  );
};
