import { FC } from 'react';
import {
  ItemBtnIconPng,
  ItemSelectedBtnIconPng,
} from '../../../../assets/images';
import { SoundButton } from '../../../../components/SoundButton';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';

interface ItemButtonProps {
  onClick?: () => void;
  isSelected?: boolean;
}

export const ItemButton: FC<ItemButtonProps> = ({
  onClick,
  isSelected = false,
}) => {
  const image = isSelected ? ItemSelectedBtnIconPng : ItemBtnIconPng;
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  return (
    <SoundButton type='button' onClick={onClick} playSound={shouldPlaySound}>
      <img
        alt='아이템'
        src={image}
        style={{ width: 124 / 2, height: 58 / 2 }}
      />
    </SoundButton>
  );
};
