import { PlaySessionType } from "../types/PlaySession.type";

export function getPresenterName(team_id: string, session: PlaySessionType) {
  try {
    const turn_info = session.turn_info[session.turn_info.length - 1];
    const teamInfo = turn_info.teams.find((v) => {
      return v._id === team_id;
    });
    const presenterId = teamInfo?.presenter ?? "";
    const presenter = session.users.find((v) => v._id === presenterId);
    return presenter?.name ?? null;
  } catch {
    return null;
  }
}
export function getPresenter(team_id: string, session: PlaySessionType) {
  try {
    const turn_info = session.turn_info[session.turn_info.length - 1];
    const teamInfo = turn_info.teams.find((v) => {
      return v._id === team_id;
    });
    const presenterId = teamInfo?.presenter ?? "";
    const presenter = session.users.find((v) => v._id === presenterId);
    return presenter
      ? {
          _id: presenter._id,
          name: presenter.name,
          select_final_choice: teamInfo?.select_final_choice ?? false,
        }
      : null;
  } catch {
    return null;
  }
}
