export function getFormattedDiff(opts: {
  price: number;
  rate?: number;
  prefixSpace?: boolean;
}) {
  const { price, rate, prefixSpace = true } = opts;
  const priceString = getFormattedPrice({
    price,
    prefixSpace,
    addSymbol: true,
  });
  let result = priceString;
  if (typeof rate === 'number') {
    const rateString = getFormattedRate(rate);
    result += ` (${rateString})`;
  }
  return result;
}
export function getFormattedRate(
  rate: number,
  opts: {
    fixNum?: number;
    addSymbol?: boolean;
    plusMinus?: boolean;
  } = {}
) {
  const { fixNum = 1, addSymbol = true, plusMinus = false } = opts;
  const isInfinite = isFinite(rate) === false;
  const prefix = plusMinus === false ? '' : rate >= 0 ? '+' : '';
  const rateString = isInfinite
    ? '-'
    : prefix + rate.toFixed(fixNum) + (addSymbol ? '%' : '');
  return rateString;
}
export function getFormattedPrice(opts: {
  price: number;
  prefixSpace: boolean;
  addSymbol?: boolean;
}) {
  const { price: _price, prefixSpace, addSymbol = false } = opts;
  const space = prefixSpace ? ' ' : '';
  const price = isNaN(_price) ? 0 : _price;
  const prefix = addSymbol === false ? '' : (price >= 0 ? '+' : '-') + space;

  const priceString = prefix + Math.abs(price).toLocaleString('ko-KR');
  return priceString;
}
