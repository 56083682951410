import { FC } from 'react';
import { ItemButton } from '../ItemButton';
import { SoundButton } from '../../../../../components/SoundButton';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';

interface PresenterMenuProps {
  onChangeMode?: (next: string) => void;
}

export const PresenterMenu: FC<PresenterMenuProps> = ({ onChangeMode }) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const goToItem = () => {
    onChangeMode?.('item');
  };
  return (
    <div className='p-5 pt-4 flex-1 overflow-y-auto'>
      <div className='flex justify-end'>
        <ItemButton onClick={goToItem} />
      </div>
      <p
        className='text-center text-darkBlueGray text-m5'
        style={{ marginTop: 139 / 2, marginBottom: 32, lineHeight: 1.5 }}
      >
        의사결정권자는
        <br />
        <strong>매수만 가능</strong>합니다
      </p>
      <div className='flex space-x-2.5'>
        <SoundButton
          className='mo-btn-stock-buy flex-1'
          onClick={() => onChangeMode?.('buy')}
          playSound={shouldPlaySound}
        >
          매수
          <br />
          <span>(사기)</span>
        </SoundButton>
        <SoundButton
          className='mo-btn-stock-sell flex-1'
          disabled
          onClick={() => onChangeMode?.('sell')}
          playSound={shouldPlaySound}
        >
          매도
          <br />
          <span>(팔기)</span>
        </SoundButton>
      </div>
    </div>
  );
};
