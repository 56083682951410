import { FC, Fragment, useState, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
interface PresenterChangeChoiceMobileModalProps {
  isRightStep: boolean;
  finalDecisionPenaltyPrice: string;
  onClose?: () => void;
}

export const PresenterChangeChoiceMobileModal: FC<
  PresenterChangeChoiceMobileModalProps
> = ({
  isRightStep,
  finalDecisionPenaltyPrice,
  onClose = () => {
    console.warn('please write onClose');
  },
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (isRightStep) {
      const timeout = setTimeout(() => {
        setIsModalOpen(false);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRightStep]);
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed top-[37.5px] left-[7.5px] right-[7.5px] bg-black bg-opacity-[0.85] rounded-[5px]"
            style={{ height: '55px' }}
          ></div>
        </Transition.Child>
        <div className="fixed top-[0px] left-[7.5px] right-[7.5px] overflow-y-auto">
          <div className="flex min-h-full justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{
                  maxWidth: 690 / 2,
                  width: 690 / 2,
                  maxHeight: 110 / 2,
                  height: 110 / 2,
                  padding: '65px 15px',
                }}
              >
                <Dialog.Panel className="w-full h-full">
                  <p className="text-p2 text-white text-center font-medium">
                    <div style={{ fontSize: 25 / 2, lineHeight: 0 }}>
                      {finalDecisionPenaltyPrice}원이 차감되었습니다
                    </div>
                  </p>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
