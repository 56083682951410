import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import classNames from 'classnames';
import { SoundButton } from './SoundButton';

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const Button: FC<ButtonProps> = (props) => {
  return (
    <SoundButton
      {...props}
      className={classNames('btn', props.className)}
      style={props.style}
    ></SoundButton>
  );
};
