import { FC } from 'react';
import {
  DeicisionIconPng,
  FreeDiscussionIconPng,
} from '../../../../assets/images';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../utils/getMyTeam';
import { getCurrentTeamDecision } from '../../../../utils/getCurrentTeamDecision';

interface Round2FinalDecisionProps {}

export const Round2FinalDecision: FC<Round2FinalDecisionProps> = () => {
  const { session, teamCode } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const { final } = getCurrentTeamDecision(myTeam?._id ?? '', session);

  if (final !== '') {
    return (
      <div className="flex-1 overflow-y-auto" style={{ padding: '110px 50px' }}>
        <h1
          className="text-center font-medium text-m5 text-darkBlueGray"
          style={{ lineHeight: 1.5 }}
        >
          <strong>최종 결정 단계 진행 중</strong>입니다
          <br />
          잠시만 기다려 주세요
        </h1>
        <img
          src={DeicisionIconPng}
          alt="최종 결정 아이콘"
          style={{ width: 242 / 2, height: 300 / 2, marginTop: 75 / 2 }}
          className="mx-auto"
        />
      </div>
    );
  }
  return (
    <div className="flex-1 overflow-y-auto p-5 pt-0">
      <div>
        <p
          className="text-center text-darkBlueGray text-m5 leading-normal"
          style={{ marginBottom: 32, paddingTop: 110 }}
        >
          팀원들과 논의 후<br />
          <strong>PC에서 정답을 체크</strong>해 주세요
        </p>
        <img
          src={FreeDiscussionIconPng}
          style={{ width: 315 / 2, height: 272 / 2 }}
          alt="의사 결정"
          className="block mx-auto"
        />
      </div>
    </div>
  );
};
