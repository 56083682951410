import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Round2WaitPage } from './wait';
import { PcBgPng } from '../../../../assets/images';

import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { Round2StepHeader } from './components/Round2StepHeader';
import { Round2StockDashBoard } from './stock-dashboard';
import { Round2FinalDecision } from './final-decision';
import { TeamPresentation } from './team-presentation';
import { StockDeal } from './stock-deal';

interface Round2IndexProps {}

const Round2Detail: FC = () => {
  const session = usePlaySessionContext();

  return (
    <div
      className="w-full h-full flex justify-center items-center"
      style={{
        backgroundImage: `url(${PcBgPng})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="w-full h-full" style={{ maxWidth: 1280, height: 800 }}>
        <div style={{ padding: '24px 50px 0' }}>
          <Round2StepHeader
            step={session.session.current_step}
            expiryTimestamp={session.session.currentStepExpiryDate}
          />
        </div>
        <Routes>
          <Route path="/stock-dashboard" Component={Round2StockDashBoard} />
          <Route path="/final-decision" Component={Round2FinalDecision} />
          <Route path="/team-presentation" Component={TeamPresentation} />
          <Route path="/stock-deal" Component={StockDeal} />
        </Routes>
      </div>
    </div>
  );
};

export const Round2Index: FC<Round2IndexProps> = () => {
  return (
    <Routes>
      <Route path="/wait" Component={Round2WaitPage}></Route>
      <Route path="/*" Component={Round2Detail}></Route>
    </Routes>
  );
};
