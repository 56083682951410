import { FC, useMemo, useState } from 'react';
import { usePlaySessionContext } from '../../../contexts/play-session/usePlaySessionContext';
import { getTeamUsers } from '../../../utils/getTeamUsers';
import { GameApis } from '../../../apis/game.apis';
import { HttpError } from '../../../apis/http-error';
import { usePlaySessionDispatch } from '../../../contexts/play-session/usePlaySessionDispatch';
import { useNow } from '../../../hooks/useNow';
import { SoundButton } from '../../../components/SoundButton';
import { useThemeContext } from '../../../contexts/theme/useThemeContext';

interface UserSelectProps {}

export const UserSelect: FC<UserSelectProps> = () => {
  const theme = useThemeContext();
  const fontColor = theme.font_color;
  const { session, teamCode } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const { dispatch } = usePlaySessionDispatch();
  const nowDate = useNow();
  const team_users = useMemo(() => {
    return getTeamUsers(session, teamCode, nowDate);
  }, [session, teamCode, nowDate]);

  const [isProcess, setIsProcess] = useState(false);

  const handleUserJoinFactory = (id: string) => async () => {
    if (isProcess === true) {
      return;
    }
    setIsProcess(true);
    try {
      const { clientId, content } = await GameApis.joinUser(
        teamCode,
        session.course_id,
        id
      );
      sessionStorage.setItem('LastLoginClientId', clientId);
      sessionStorage.setItem('LastLoginInfo', content);
      dispatch((prev) => ({ ...prev, userId: id }));
    } catch (e) {
      const err = e as HttpError;
      alert(err.message);
    } finally {
      setIsProcess(false);
    }
  };

  return (
    <div className='w-full min-h-full bg-theme' style={{ padding: 30 }}>
      <h1
        className='text-white text-m5 text-center '
        style={{ marginTop: 31, marginBottom: 47, color: fontColor }}
      >
        본인의 이름을 선택해 주세요
      </h1>
      <div className='space-y-2.5'>
        {team_users.map((v) => {
          return (
            <SoundButton
              className='block w-full bg-white rounded-lg text-darkBlueGray disabled:bg-gray border-veryLightPink border-[1.5px] disabled:border-brownishGrey disabled:text-brownishGrey'
              key={v._id}
              disabled={v.isJoined}
              type='button'
              style={{ padding: 9.5 }}
              onClick={handleUserJoinFactory(v._id)}
              playSound={shouldPlaySound}
            >
              <p className='text-m5 mb-1.5 flex items-center justify-center font-medium'>
                {v.teamName}팀
                <strong className='text-m3' style={{ margin: '0 3px 0 5px' }}>
                  {v.name}
                </strong>
                <span className='self-end'>님</span>
              </p>
              <p className='text-m8 font-medium'>{v.info_1}</p>
            </SoundButton>
          );
        })}
      </div>
    </div>
  );
};
