import { FC } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../utils/getMyTeam';
import { getScenarioInfo } from '../../../../utils/getScenarioInfo';
import { ScenarioViewer } from '../components/ScenarioViewer';

interface StockDealProps {}

export const StockDeal: FC<StockDealProps> = () => {
  const { session, teamCode } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const scenario = getScenarioInfo({
    myTeam,
    balance: session.balance,
    playtime: session.playtime,
    round: session.current_round,
    scenarioInfo: session.gameScenarios,
    turn_info: session.turn_info,
  });

  if (scenario == null) {
    return (
      <div style={{ padding: '0 50px 51px' }}>
        <p className="text-p3 text-white font-medium text-center">
          사례 정보가 존재하지 않습니다.
        </p>
      </div>
    );
  }

  return (
    <div style={{ padding: '0 50px 51px' }}>
      <ScenarioViewer {...scenario} />
    </div>
  );
};
