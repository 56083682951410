import { PlaySessionType } from '../types/PlaySession.type';

export function getCurrentTeamDecision(
  teamId: string,
  session: PlaySessionType
) {
  try {
    const curr_turn_info = session.turn_info[session.turn_info.length - 1];
    const teamInfo = curr_turn_info.teams.find((v) => v._id === teamId);
    return {
      first: teamInfo?.first_choice ?? '-',
      final: teamInfo?.final_choice ?? '-',
    };
  } catch {
    return {
      first: '-',
      final: '-',
    };
  }
}
