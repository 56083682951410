import { FC, useState } from 'react';
import { MyCashModal } from './components/MyCashModal';
import {
  MyCashActivePng,
  MyCashPng,
  ResultBtnIconPng,
} from '../../../assets/images';
import { SoundButton } from '../../../components/SoundButton';
import { useThemeContext } from '../../../contexts/theme/useThemeContext';
import { usePlaySessionContext } from '../../../contexts/play-session/usePlaySessionContext';

interface ResultPageProps {}

export const ResultPage: FC<ResultPageProps> = () => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const theme = useThemeContext();
  const fontColor = theme.font_color;
  const [openMyCashModal, setOpenMyCashModal] = useState(false);
  const handleCloseMyCashModal = () => setOpenMyCashModal(false);
  const handleOpenMyCashModal = () => setOpenMyCashModal(true);
  return (
    <div className='w-full h-full p-5 bg-theme flex flex-col'>
      {/* <div className="flex justify-end"> */}
      <MyCashModal isOpen={openMyCashModal} onClose={handleCloseMyCashModal} />
      <div className='flex-1 flex flex-col justify-center items-center p-4'>
        <p
          className='text-white text-m5 font-medium text-center'
          style={{ lineHeight: 1.5, color: fontColor }}
        >
          게임이 종료되었습니다
        </p>
        <SoundButton
          className='group'
          style={{ marginTop: 50 / 2 }}
          type='button'
          onClick={handleOpenMyCashModal}
          playSound={shouldPlaySound}
        >
          <img
            src={ResultBtnIconPng}
            alt='결과보기'
            style={{ width: 410 / 2, height: 100 / 2 }}
          />
        </SoundButton>
      </div>
    </div>
  );
};
