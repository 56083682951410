import { FC, useEffect, useState } from 'react';
import { StockCharts } from '../components/StockChart';
import { StockChangeModal } from '../components/StockChangeModal';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import classNames from 'classnames';
import { getFormattedDiff } from '../../../../utils/getFormattedDiff';
import { getStockInfo } from '../../../../utils/getStockInfo';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';
import { getPresenterName } from '../../../../utils/getPresenterName';

interface StockChartProps {}

interface ToastProps {
  message: string;
}

const Toast: React.FC<ToastProps> = ({ message }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      width: '770px',
      height: '128px',
      border: '5px solid #f47724',
      borderRadius: '10px',
      backgroundColor: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#172547',
      fontFamily: 'GmarketSans',
      fontSize: '28px',
      fontWeight: '500',
      padding: 10,
      zIndex: 1000,
    }}
  >
    <span>
      다음 차례는 <b>{message}</b> 님 입니다
    </span>
  </div>
);

const AnswerMap: Record<string, string> = {
  '1': 'first_answer_description',
  '2': 'second_answer_description',
  '3': 'third_answer_description',
};

export const StockChart: FC<StockChartProps> = () => {
  const [open, setOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const theme = useThemeContext();
  const { session, teamCode } = usePlaySessionContext();
  const team = session.teams.find((v) => v.code === teamCode);
  const stock = session.stocks.find((v) => v._id === team?._id);
  const presenter = getPresenterName(team?._id ?? '', session);

  const teamName = team?.name ?? 'NONE';
  const { prev_price, price } = getStockInfo(
    team?._id ?? 'none',
    session.stocks
  );
  const currentPrice = price;
  const prevPrice = prev_price;
  const diffPrice = currentPrice - prevPrice;
  const _diffPercent = (diffPrice / prevPrice) * 100;
  const diffPercent = prevPrice === 0 ? 0 : _diffPercent;
  const prev_scenario = session.gameScenarios.find(
    (v) => v._id === session.turn_info[session.turn_info.length - 2]?.scenario
  );
  const select_team_answer =
    session.turn_info[session.turn_info.length - 2]?.teams?.find(
      (v) => v._id === team?._id
    )?.final_choice ?? '';

  const teamStockHistory = (stock?.price_info ?? [])
    .filter((v, _, arr) => {
      const result = !(arr.length >= 3 && v.turn === 0);
      return result;
    })
    .map((v) => {
      return {
        [teamName]: v.price,
      };
    });

  useEffect(() => {
    if (session.current_turn !== 1 || session.current_round !== 1) {
      setOpen(true);
    }
  }, [session.current_round, session.current_turn]);

  // useEffect(() => {
  //   const checkTimeAndOpenModal = () => {
  //     const now = new Date();
  //     const expiryTime = new Date(session.currentStepExpiryDate);
  //     const remainingTime = (expiryTime.getTime() - now.getTime()) / 1000;
  //     if (remainingTime <= 5) {
  //       setShowToast(true);
  //       setTimeout(() => setShowToast(false), 5000);
  //     }
  //   };
  //   // 1초마다 남은 시간 확인
  //   const interval = setInterval(checkTimeAndOpenModal, 1000);
  //   // 컴포넌트 언마운트 시 인터벌 정리
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [session]);

  const teamColor = theme.pc_stock_status_team_color;

  return (
    <div className='w-full relative'>
      {showToast && presenter && <Toast message={presenter} />}
      <StockChangeModal
        isOpen={open}
        diff={diffPrice}
        content={
          (prev_scenario as any)?.[AnswerMap[select_team_answer ?? 'NONE']] ??
          '답변을 선택하지 않으셨습니다.'
        }
        onClose={() => setOpen(false)}
      />
      <p
        className='text-p4 text-white font-medium'
        style={{ marginLeft: 77, marginTop: 50, marginBottom: 23 }}
      >
        <strong
          style={{
            color: teamColor,
          }}
        >
          {teamName}팀
        </strong>{' '}
        현재 주가
      </p>
      <div
        className='flex items-end flex-col'
        style={{ marginLeft: 77, width: 344, marginBottom: 15 }}
      >
        <p className='text-white font-medium mb-2.5 text-p2'>
          <strong>{currentPrice.toLocaleString('ko-kr')}</strong>
          <span style={{ fontSize: 34, lineHeight: 1 }}>원</span>
        </p>
        <p
          className={classNames('font-medium text-p4 ', {
            'text-coral': diffPrice >= 0,
            'text-dodgerBlue': diffPercent < 0,
          })}
        >
          {getFormattedDiff({
            price: diffPrice,
            prefixSpace: false,
            rate: diffPercent,
          })}
        </p>
      </div>
      <div style={{ height: 510, marginTop: -23 }}>
        <StockCharts data={teamStockHistory} myTeamId={teamName} />
      </div>
    </div>
  );
};
