import { FC, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { StockDashboard } from "./stock-dashboard";
import { Header } from "../components/Header";
import { FirstDecision } from "./first-decision";
import { PersonalStatement } from "./personal-statement";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";
import { getExpiryData } from "../../../../utils/getExpiryData";
import { FreeDiscussion } from "./free-discussion";
import { FinalDecision } from "./final-decision";
import { StockDeal } from "./stock-deal";

interface Round1IndexProps {}
export const Round1Index: FC<Round1IndexProps> = () => {
  const { session, teamCode } = usePlaySessionContext();
  const { endDates, orderUsers, startDates } = getExpiryData(teamCode, session);

  const [timeIndex, setTimeIndex] = useState(0);
  useEffect(() => {
    if (endDates) {
      if (endDates.length === 1) {
        setTimeIndex(0);
      }
      const now = new Date();
      const startIndex = endDates.findIndex((v) => v > now);

      if (startIndex > -1) {
        setTimeIndex(startIndex);
      } else {
        setTimeIndex(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDates]);

  return (
    <div className="w-full flex flex-col h-full">
      <Header
        startDates={startDates[timeIndex]}
        endDates={endDates[timeIndex]}
        onExpiry={() => {
          setTimeIndex((prev) => {
            return Math.max(Math.min(prev + 1, (endDates ?? []).length - 1), 0);
          });
        }}
      />
      <Routes>
        <Route path="/stock-chart" Component={StockDashboard} />
        <Route path="/first-decision" Component={FirstDecision} />
        <Route
          path="/personal-statement"
          element={
            <PersonalStatement
              currentUser={orderUsers?.[timeIndex]?.name ?? "NONE"}
              nextUser={orderUsers?.[timeIndex + 1]?.name}
            />
          }
        />
        <Route path="/free-discussion" Component={FreeDiscussion} />
        <Route path="/final-decision" Component={FinalDecision} />
        <Route path="/stock-deal" Component={StockDeal} />
      </Routes>
    </div>
  );
};
