import { FC, useState } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../utils/getMyTeam';
import { getScenarioInfo } from '../../../../utils/getScenarioInfo';
import { ScenarioViewer } from '../components/ScenarioViewer';
import { SelectAnswerModal } from '../components/SelectAnswerModal';
import { HttpError } from '../../../../apis/http-error';
import { GameApis } from '../../../../apis/game.apis';
import { TeamChoiceModal } from '../components/TeamChoiceModal';
import { GameStep } from '../../../../constants/step-enum';

interface Round2FinalDecisionProps {}

export const Round2FinalDecision: FC<Round2FinalDecisionProps> = () => {
  const { session, teamCode } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const scenario = getScenarioInfo({
    myTeam,
    balance: session.balance,
    playtime: session.playtime,
    round: session.current_round,
    scenarioInfo: session.gameScenarios,
    turn_info: session.turn_info,
  });
  const [modal, setModal] = useState({ isOpen: false, answer: '' });
  const handleOpenModal = (answer: string) => {
    setModal({
      isOpen: true,
      answer,
    });
  };
  const handleCloseModal = () => {
    setModal({
      isOpen: false,
      answer: '',
    });
  };
  const handleSubmitModal = async () => {
    try {
      await GameApis.selectTeamDecision({
        sessionId: session._id,
        teamId: myTeam?._id ?? 'none',
        answer: modal.answer,
      });
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
      throw e;
    }
  };

  if (scenario == null) {
    return (
      <div style={{ padding: '0 50px 51px' }}>
        <p className="text-p3 text-white font-medium text-center">
          사례 정보가 존재하지 않습니다.
        </p>
      </div>
    );
  }

  return (
    <div style={{ padding: '0 50px 51px' }}>
      <SelectAnswerModal
        isOpen={modal.isOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
      />
      <ScenarioViewer
        {...scenario}
        selectable={scenario.select === ''}
        onSelectAnswer={handleOpenModal}
      />
      <TeamChoiceModal
        isOpen={session.current_step === GameStep.FINAL_DECISION_DONE}
        name={myTeam?.name ?? 'NONE'}
        select={scenario.select}
      />
    </div>
  );
};
