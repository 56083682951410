import { FC, useState, useEffect, FormEventHandler } from "react";
import { useThemeContext } from "../../contexts/theme/useThemeContext";
import { useNavigate } from "react-router-dom";
import { GameApis } from "../../apis/game.apis";
import { HttpError } from "../../apis/http-error";
import { SoundButton } from "../../components/SoundButton";
import { usePlaySessionContext } from "../../contexts/play-session/usePlaySessionContext";

interface IndexPageProps {}

const LoginPage: FC = () => {
  // const { session } = usePlaySessionContext();
  // const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const theme = useThemeContext();
  const [accessCode, setAccessCode] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
    setIsSubmitting(true);
    try {
      const res = await GameApis.joinTeam(accessCode);
      navigate("/game", {
        state: { session: res, teamCode: accessCode },
      });
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="w-full h-full overflow-y-auto bg-theme"
      style={{ padding: 30 }}
    >
      <div className="flex min-h-full justify-center items-center flex-col">
        {theme.logo ? (
          <img
            alt="logo"
            src={theme.logo}
            className="w-full object-contain"
            style={{ margin: "0 4.5px 73.5px" }}
          />
        ) : null}
        <form className="w-full space-y-2.5" onSubmit={handleSubmit}>
          <p className="font-medium text-white text-m10">접속 번호</p>
          <input
            type="text"
            placeholder="접속번호를 입력해주세요"
            className="mo-txtInput w-full"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value.trim())}
            maxLength={7}
          />
          <SoundButton
            className="mo-btn w-full"
            type="submit"
            disabled={isSubmitting || accessCode.length !== 7}
            playSound={false}
          >
            접속
          </SoundButton>
        </form>
      </div>
    </div>
  );
};

function searchToObject(str: string) {
  var pairs = str.substring(1).split("&"),
    obj: Record<string, string> = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}

export const IndexPage: FC<IndexPageProps> = () => {
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 여기서 자동로그인 처리 실패시 setIsReady true
    let mounted = true;
    const lastLoginClientId = sessionStorage.getItem("LastLoginClientId") ?? "";
    const lastLoginInfo = sessionStorage.getItem("LastLoginInfo") ?? "";
    const [, locationClientId] = window.location.pathname.split(/[/,?]/);
    if (lastLoginClientId === locationClientId) {
      GameApis.userAutoLogin(lastLoginInfo)
        .then((res) => {
          if (mounted === false) {
            return;
          }
          if (!res) {
            return setIsReady(true);
          }
          const user = res.session.users.find((v) => v._id === res.userId);
          const team = res.session.teams.find((v) => v._id === user?.team_id);
          if (!user || !team) {
            return setIsReady(true);
          }
          navigate("/game", {
            state: {
              session: res.session,
              teamCode: team.code,
              userId: user._id,
            },
          });
        })
        .catch(() => {
          sessionStorage.removeItem("LastLoginClientId");
          sessionStorage.removeItem("LastLoginInfo");
          setIsReady(true);
        });
    } else if (lastLoginClientId !== "") {
      sessionStorage.removeItem("LastLoginClientId");
      sessionStorage.removeItem("LastLoginInfo");
      setIsReady(true);
    } else {
      const search = window.location.search;
      const query = searchToObject(search);
      if (query.t) {
        GameApis.joinTeam(query.t)
          .then((session) => {
            navigate("/game", {
              state: { session: session, teamCode: query.t },
            });
          })
          .catch(() => {
            setIsReady(true);
          });
      } else {
        setIsReady(true);
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isReady === false) {
    return <div className="w-full h-full bg-theme" />;
  }

  return <LoginPage />;
};
