import { PlaySessionType } from '../types/PlaySession.type';

export function getScenarioInfo(opts: {
  myTeam?: { _id: string; code: string; name: string };
  round: number;
  scenarioInfo: PlaySessionType['gameScenarios'];
  turn_info: PlaySessionType['turn_info'];
  balance: PlaySessionType['balance'];
  playtime: PlaySessionType['playtime'];
}) {
  try {
    const { balance, scenarioInfo, turn_info, playtime, round, myTeam } = opts;
    const current_turn_info = turn_info[turn_info.length - 1];
    const index = turn_info.length - 1;
    let rewardIndex = index % 6;
    if (round === 2) {
      rewardIndex = (index - playtime.round_1_turn) % 3;
    }
    let answerReward = 0;
    let failedReward = 0;
    switch (round) {
      case 1:
        switch (rewardIndex) {
          case 0: {
            answerReward = balance.round_1_scenario_1_answer_reward;
            failedReward = balance.round_1_scenario_1_failed_panelty;
            break;
          }
          case 1: {
            answerReward = balance.round_1_scenario_2_answer_reward;
            failedReward = balance.round_1_scenario_2_failed_panelty;
            break;
          }
          case 2: {
            answerReward = balance.round_1_scenario_3_answer_reward;
            failedReward = balance.round_1_scenario_3_failed_panelty;
            break;
          }
          case 3: {
            answerReward = balance.round_1_scenario_4_answer_reward;
            failedReward = balance.round_1_scenario_4_failed_panelty;
            break;
          }
          case 4: {
            answerReward = balance.round_1_scenario_5_answer_reward;
            failedReward = balance.round_1_scenario_5_failed_panelty;
            break;
          }
          case 5: {
            answerReward = balance.round_1_scenario_6_answer_reward;
            failedReward = balance.round_1_scenario_6_failed_panelty;
            break;
          }
          default: {
          }
        }
        break;
      case 2:
        switch (rewardIndex) {
          case 0: {
            answerReward = balance.round_2_scenario_1_answer_reward;
            failedReward = balance.round_2_scenario_1_failed_panelty;
            break;
          }
          case 1: {
            answerReward = balance.round_2_scenario_2_answer_reward;
            failedReward = balance.round_2_scenario_2_failed_panelty;
            break;
          }
          case 2: {
            answerReward = balance.round_2_scenario_3_answer_reward;
            failedReward = balance.round_2_scenario_3_failed_panelty;
            break;
          }
          default: {
            break;
          }
        }
        break;
      default: {
      }
    }
    const current_scenario = scenarioInfo.find(
      (v) => v._id === current_turn_info.scenario
    );
    const answerInfo = current_turn_info?.teams?.find(
      (v) => v._id === myTeam?._id
    );

    return {
      index: index + 1,
      answer_reward: answerReward,
      failed_panelty: failedReward,
      content: current_scenario?.content ?? '',
      answers: {
        first: current_scenario?.first_answer ?? '',
        second: current_scenario?.second_answer ?? '',
        third: current_scenario?.third_answer ?? '',
      },
      descriptions: {
        first: current_scenario?.first_answer_description ?? '',
        second: current_scenario?.second_answer_description ?? '',
        third: current_scenario?.third_answer_description ?? '',
      },
      select: answerInfo?.final_choice ?? '',
      first_select: answerInfo?.first_choice ?? '',
      final_select: answerInfo?.final_choice ?? '',
    };
  } catch (e) {
    return null;
  }
}
