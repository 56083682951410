import { FC, useState } from 'react';
import { BackButton } from '../BackButton';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';
import { getStockAnswerGroup } from '../../../../../utils/getStockAnswerGroup';
import {
  getFormattedDiff,
  getFormattedPrice,
} from '../../../../../utils/getFormattedDiff';
import classNames from 'classnames';
import { SoundButton } from '../../../../../components/SoundButton';

interface BuyStockListProps {
  onChangeMode?: (next: string) => void;
}

const AnswerIcon: FC<{ children?: any }> = ({ children }) => {
  return (
    <div
      className='flex justify-center items-end bg-darkBlueGray rounded-full mb-1 mr-0.5'
      style={{ width: 16, height: 16, paddingBottom: 2, paddingLeft: 1 }}
    >
      <span
        className='font-bold text-white leading-none'
        style={{ fontSize: 10 }}
      >
        {children}
      </span>
    </div>
  );
};

export const BuyStockList: FC<BuyStockListProps> = ({ onChangeMode }) => {
  const { session, userId = '' } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  const onBack = () => {
    onChangeMode?.('menu');
  };
  const [select, setSelect] = useState('');
  const selectFactory = (next: string) => () => {
    setSelect(next);
    setOpen(false);
  };
  const stockGroup = getStockAnswerGroup(userId, session);
  const currentStockGroup = stockGroup[select] ?? [];
  const selectBuyGenerator = (id: string) => () => {
    if (id === '') {
      return;
    }
    onChangeMode?.('buy::' + id);
  };
  return (
    <div
      className='flex-1 overflow-y-auto'
      style={{ padding: '16px 20px 20px' }}
    >
      <BackButton onClick={onBack} />
      <div className='mt-2'>
        <div className='relative'>
          <div>
            <div className='bg-coral pt-4 pb-3 rounded-t-xl'>
              <p className='text-center text-white text-m6 '>매수(사기)</p>
            </div>
            <div
              className='border border-t-0 px-3.5 py-6 rounded-b-xl'
              style={{
                backgroundColor: select === '' ? 'white' : '#ffebeb',
                borderColor: select === '' ? '#dedede' : '#ffd3d3',
              }}
            >
              <SoundButton
                className='flex justify-between w-full'
                type='button'
                onClick={toggleOpen}
                playSound={shouldPlaySound}
              >
                {select === '' ? (
                  <p
                    className='text-m6 font-medium'
                    style={{ color: '#c6c6c6' }}
                  >
                    매수를 진행할 팀을 선택하세요
                  </p>
                ) : (
                  <div className='flex items-center'>
                    <AnswerIcon>{select}</AnswerIcon>
                    <p className='text-left text-m6 text-darkBlueGray font-medium'>
                      번 선택 팀
                    </p>
                  </div>
                )}
                <div
                  className='border-darkBlueGray'
                  style={{
                    width: 28 / 2,
                    borderTopWidth: 22 / 2,
                    borderLeftWidth: 14 / 2,
                    borderLeftColor: 'transparent',
                    borderRightWidth: 14 / 2,
                    borderRightColor: 'transparent',
                    marginRight: 8,
                  }}
                ></div>
              </SoundButton>
            </div>
          </div>
          {open ? (
            <div className='absolute inset-x-0 z-10'>
              <div
                className='border border-veryLightPink bg-white rounded-xl'
                style={{ marginTop: 5, padding: 2.5 }}
              >
                <SoundButton
                  className='w-full rounded-xl'
                  style={{
                    paddingLeft: 14,
                    paddingRight: 14,
                    backgroundColor: select === '1' ? '#ffebeb' : 'transparent',
                  }}
                  type='button'
                  onClick={selectFactory('1')}
                  playSound={shouldPlaySound}
                >
                  <div
                    className='flex items-center'
                    style={{ paddingTop: 14, paddingBottom: 10 }}
                  >
                    <AnswerIcon>1</AnswerIcon>
                    <p className='text-left text-m6 text-darkBlueGray font-medium'>
                      번 선택 팀
                    </p>
                  </div>
                </SoundButton>
                <div style={{ paddingLeft: 14, paddingRight: 14 }}>
                  <div
                    className='border-b border-veryLightPink'
                    style={{ margin: '2.5px 0' }}
                  ></div>
                </div>
                <SoundButton
                  className='w-full rounded-xl'
                  style={{
                    paddingLeft: 14,
                    paddingRight: 14,
                    backgroundColor: select === '2' ? '#ffebeb' : 'transparent',
                  }}
                  type='button'
                  onClick={selectFactory('2')}
                  playSound={shouldPlaySound}
                >
                  <div
                    className='flex items-center'
                    style={{ paddingTop: 14, paddingBottom: 10 }}
                  >
                    <AnswerIcon>2</AnswerIcon>
                    <p className='text-left text-m6 text-darkBlueGray font-medium'>
                      번 선택 팀
                    </p>
                  </div>
                </SoundButton>
                <div style={{ paddingLeft: 14, paddingRight: 14 }}>
                  <div
                    className='border-b border-veryLightPink'
                    style={{ margin: '2.5px 0' }}
                  ></div>
                </div>
                <SoundButton
                  className='w-full rounded-xl'
                  style={{
                    paddingLeft: 14,
                    paddingRight: 14,
                    backgroundColor: select === '3' ? '#ffebeb' : 'transparent',
                  }}
                  type='button'
                  onClick={selectFactory('3')}
                  playSound={shouldPlaySound}
                >
                  <div
                    className='flex items-center'
                    style={{ paddingTop: 14, paddingBottom: 10 }}
                  >
                    <AnswerIcon>3</AnswerIcon>
                    <p className='text-left text-m6 text-darkBlueGray font-medium'>
                      번 선택 팀
                    </p>
                  </div>
                </SoundButton>
              </div>
            </div>
          ) : null}
        </div>
        <div className='px-4 py-2.5'>
          {currentStockGroup.map((v) => {
            return (
              <div
                key={v._id}
                className='py-2.5 border-b'
                style={{ borderBottomColor: '#ffd3d3' }}
              >
                <div className='flex items-center'>
                  <div className='self-start'>
                    <p>{v.name}팀</p>
                  </div>
                  <div className='ml-auto'>
                    <p
                      className='text-right text-darkBlueGray text-m6 font-medium '
                      style={{ marginBottom: 5 }}
                    >
                      <strong>
                        {getFormattedPrice({
                          price: v.price,
                          prefixSpace: false,
                          addSymbol: false,
                        })}
                      </strong>{' '}
                      원
                    </p>
                    <p
                      className={classNames('text-right font-medium text-m7', {
                        'text-coral': v.diff >= 0,
                        'text-dodgerBlue': v.diff < 0,
                      })}
                    >
                      {getFormattedDiff({
                        price: v.diff,
                        rate: v.diffRate,
                        prefixSpace: false,
                      })}
                    </p>
                  </div>
                  <div className='ml-4'>
                    <SoundButton
                      type='button'
                      className='pt-1 rounded-xl bg-coral'
                      style={{
                        width: 45,
                        height: 45,
                        boxShadow: '0 2px 3px 0 rgba(255, 175, 175, 0.3)',
                      }}
                      onClick={selectBuyGenerator(v._id)}
                      playSound={shouldPlaySound}
                    >
                      <span className='text-white font-medium text-center'>
                        매수
                      </span>
                    </SoundButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
