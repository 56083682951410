import { FC } from 'react';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';

interface Round2WaitPageProps {}

export const Round2WaitPage: FC<Round2WaitPageProps> = () => {
  const theme = useThemeContext();
  const fontColor = theme.font_color;
  return (
    <div className='w-full h-full flex items-center justify-center flex-col bg-theme'>
      <h1
        className='text-p3 text-white text-center'
        style={{ lineHeight: 1.5, color: fontColor }}
      >
        곧 2ROUND가 시작됩니다
        <br />
        잠시만 기다려 주세요
      </h1>
    </div>
  );
};
