import { FC, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PlaySessionProvider } from '../../../contexts/play-session/provider';
import { UserSelect } from './user-select';
import { useAutoStepMo } from './hooks/useAutoStepMo';
import { WaitPage } from './wait';
import { Round1Index } from './round1';
import { Round2Index } from './round2';
import { ResultPage } from './result';
import { usePlaySessionContext } from '../../../contexts/play-session/usePlaySessionContext';
import { GameStep } from '../../../constants/step-enum';
import { useThemeContext } from '../../../contexts/theme/useThemeContext';

interface GamePageProps {}

const AutoStep: FC = () => {
  useAutoStepMo();
  return null;
};

const GameRouter: FC = () => {
  const theme = useThemeContext();
  const fontColor = theme.font_color;
  const { session } = usePlaySessionContext();
  const isPause =
    (session.current_round === 1 &&
      session.current_step !== GameStep.WAIT &&
      session.game_start === false) ||
    (session.current_round === 2 &&
      session.current_step !== GameStep.WAIT &&
      session.current_step !== GameStep.RESULT &&
      session.round_2_start === false);
  if (isPause === true) {
    return (
      <div className='w-full h-full flex flex-col justify-center items-center bg-theme'>
        <p
          className='text-white text-m5 font-medium text-center'
          style={{ lineHeight: 1.5, color: fontColor }}
        >
          일시정지 되었습니다
        </p>
      </div>
    );
  }

  return (
    <Routes>
      <Route path='/wait' Component={WaitPage} />
      <Route path='/result' Component={ResultPage} />
      <Route path='/user-select' Component={UserSelect}></Route>
      <Route path='/round1/*' Component={Round1Index}></Route>
      <Route path='/round2/*' Component={Round2Index}></Route>
    </Routes>
  );
};

export const GamePage: FC<GamePageProps> = () => {
  const location = useLocation();
  const [initData] = useState<{ session: any; teamCode: any; userId?: any }>({
    session: location.state?.session ?? null,
    teamCode: location.state?.teamCode ?? null,
    userId: location.state?.userId ?? null,
  });

  return (
    <PlaySessionProvider
      initialSession={initData.session}
      teamCode={initData.teamCode}
      userId={initData.userId}
    >
      <AutoStep />
      <GameRouter />
    </PlaySessionProvider>
  );
};
