import { FC, useState } from 'react';
import {
  BaedangumBtnDisabledPng,
  BaedangumBtnPng,
  BaedangumInfoPng,
  FullBuyBtnDisabledPng,
  FullBuyBtnPng,
  FullBuyInfoPng,
  GetInfoBtnDisabledPng,
  GetInfoBtnPng,
  GetInfoInfoPng,
  LoanBtnDisabledPng,
  LoanBtnPng,
  LoanInfoPng,
} from '../../../../../assets/images';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';
import { BottonSheetModal } from '../BottonSheetModal';
import { GameApis } from '../../../../../apis/game.apis';
import { HttpError } from '../../../../../apis/http-error';
import { GetInfoModal } from './GetInfoModal';
import { BackButton } from '../BackButton';
import { ItemButton } from '../ItemButton';
import { useThemeContext } from '../../../../../contexts/theme/useThemeContext';
import { SoundButton } from '../../../../../components/SoundButton';

interface ItemInfoProps {
  onBack: () => void;
  onBackPrevItem: () => void;
}
const MAX_USED_ITEM = 2;
function useModal<T = {}>(initialData?: T) {
  const [state, setState] = useState({
    isOpen: false,
    ...(initialData ?? {}),
  } as { isOpen: boolean } & T);
  return {
    data: state,
    open: (initialData?: T) =>
      setState((prev) => ({ ...prev, isOpen: true, ...(initialData ?? {}) })),
    close: () => setState((prev) => ({ ...prev, isOpen: false })),
  };
}

export const ItemInfo: FC<ItemInfoProps> = ({ onBack, onBackPrevItem }) => {
  const theme = useThemeContext();
  const { session, userId = '' } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const [showGetInfoModal, setShowGetInfoModal] = useState(false);
  const fullBuyInfoModal = useModal();
  const baedanggumInfoModal = useModal();
  const getInfoInfoModal = useModal();
  const loanInfoModal = useModal();
  const user = session.users.find((v) => v._id === userId);
  const submitBackgroundColor = theme.use_item_box_color;
  const item = user?.item_info?.[0] || {
    baedanggum: false,
    full_buyed: false,
    get_info: false,
    loan: false,
    loan_return_flag: false,
    round: false,
  };
  const usedItemCount = Object.entries(item).reduce((acc, [key, val]) => {
    if (key === 'baedanggum' && val === true) {
      acc++;
    }
    if (key === 'full_buyed' && val === true) {
      acc++;
    }
    if (key === 'get_info' && val === true) {
      acc++;
    }
    if (key === 'loan' && val === true) {
      acc++;
    }
    return acc;
  }, 0);
  const REST_ITEM_COUNT = MAX_USED_ITEM - usedItemCount;
  const generateHandleUse = (func: () => Promise<void>) => () => {
    if (REST_ITEM_COUNT <= 0) {
      return alert('더이상 사용할 수 없습니다.');
    }
    return func();
  };

  const handleFullBuy = generateHandleUse(async () => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await GameApis.useFullBuy({
        userId,
        session_id: session._id,
      });
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    }
  });
  const handleBaedangum = generateHandleUse(async () => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await GameApis.useBaedangum({
        userId,
        session_id: session._id,
      });
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    }
  });
  const handleGetInfo = generateHandleUse(async () => {
    setShowGetInfoModal(true);
  });
  const handleLoan = generateHandleUse(async () => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await GameApis.useLoan({
        userId,
        session_id: session._id,
      });
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    }
  });
  const confirmModal = useModal({ item_label: '', item: '' });
  const itemInfoSubmitHandler = (item: string, item_label: string) => () => {
    confirmModal.open({ item, item_label });
    switch (item) {
      case 'fullbuy':
        fullBuyInfoModal.close();
        break;
      case 'baedangum':
        baedanggumInfoModal.close();
        break;
      case 'getInfo':
        getInfoInfoModal.close();
        break;
      case 'loan':
        loanInfoModal.close();
        break;
      default: {
        alert('알 수 없는 아이템입니다.');
      }
    }
  };
  const handleSubmit = async () => {
    switch (confirmModal.data.item) {
      case 'fullbuy':
        await handleFullBuy();
        break;
      case 'baedangum':
        await handleBaedangum();
        break;
      case 'getInfo':
        await handleGetInfo();
        break;
      case 'loan':
        await handleLoan();
        break;
      default: {
        alert('알 수 없는 아이템입니다.');
      }
    }
  };

  return (
    <div style={{ padding: '16px 20px 20px' }}>
      <GetInfoModal
        isOpen={showGetInfoModal}
        onClose={() => {
          setShowGetInfoModal(false);
        }}
      />
      <BottonSheetModal
        cancelText='닫기'
        submitText='사용'
        isOpen={fullBuyInfoModal.data.isOpen}
        onClose={fullBuyInfoModal.close}
        onSubmit={itemInfoSubmitHandler('fullbuy', '풀매수')}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div className='flex' style={{ paddingLeft: 30, paddingRight: 30 }}>
          <img
            src={FullBuyInfoPng}
            alt='이번 매매 단계에서 주식 매수량 제한을 해제하고 현금 보유량의 100%까지 매수가 가능합니다.'
          />
        </div>
      </BottonSheetModal>
      <BottonSheetModal
        cancelText='닫기'
        submitText='사용'
        isOpen={baedanggumInfoModal.data.isOpen}
        onClose={baedanggumInfoModal.close}
        onSubmit={itemInfoSubmitHandler('baedangum', '배당금')}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div className='flex' style={{ paddingLeft: 32.4, paddingRight: 52 }}>
          <img
            src={BaedangumInfoPng}
            alt='이번 매매 단계 종료 후 평가금액의 5%를 배당금(현금)으로 지급받습니다.'
          />
        </div>
      </BottonSheetModal>
      <BottonSheetModal
        cancelText='닫기'
        submitText='사용'
        isOpen={getInfoInfoModal.data.isOpen}
        onClose={getInfoInfoModal.close}
        onSubmit={itemInfoSubmitHandler('getInfo', '정보 획득')}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div
          className='flex'
          style={{ paddingLeft: 50.5 / 2, paddingRight: 113 / 2 }}
        >
          <img
            src={GetInfoInfoPng}
            alt='이번 사례의 보기들 중 하나를 선택하여 예상되는 결과를 미리 확인합니다.'
          />
        </div>
      </BottonSheetModal>
      <BottonSheetModal
        cancelText='닫기'
        submitText='사용'
        isOpen={loanInfoModal.data.isOpen}
        onClose={loanInfoModal.close}
        onSubmit={itemInfoSubmitHandler('loan', '대출')}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div
          className='flex'
          style={{ paddingLeft: 58 / 2, paddingRight: 52 / 2 }}
        >
          <img
            src={LoanInfoPng}
            alt='현재 보유한 총 자산 대비 30%를 현금으로 즉시 지급받습니다. 단 1 라운드 종료 시 5% 이자와 함께 일괄 상환됩니다. (상환금액이 부족한 경우 부족한 만큼 주식이 자동 매도됩니다.)'
          />
        </div>
      </BottonSheetModal>
      <BottonSheetModal
        cancelText='닫기'
        submitText='사용'
        isOpen={confirmModal.data.isOpen}
        onClose={confirmModal.close}
        onSubmit={handleSubmit}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div className='p-5 pt-10'>
          <p
            className='text-m6 text-center font-medium'
            style={{ lineHeight: 1.5 }}
          >
            한 턴에 하나의 아이템만 사용 가능합니다.
            <br />
            {confirmModal.data.item_label}을 사용 하시겠습니까?
          </p>
        </div>
      </BottonSheetModal>
      <div className='flex justify-between'>
        <BackButton onClick={onBack} />
        <ItemButton onClick={onBackPrevItem} isSelected />
      </div>
      <div className='mt-2 bg-veryLightPink2 border-veryLightPink border p-4 rounded-xl '>
        <div className='flex justify-between border-b-veryLightPink border-b pb-1'>
          <p className='text-darkBlueGray text-m6 font-medium'>
            사용 가능한 아이템 개수 :
          </p>
          <p className='text-darkBlueGray text-m6 font-medium'>
            <strong className='text-coral'>{REST_ITEM_COUNT}</strong>개
          </p>
        </div>
        <div className='space-y-2 mt-4'>
          <div className='flex space-x-2'>
            <SoundButton
              className='flex-1'
              onClick={fullBuyInfoModal.open}
              disabled={item.full_buyed === true}
              playSound={shouldPlaySound}
            >
              <img
                src={
                  item.full_buyed === true
                    ? FullBuyBtnDisabledPng
                    : FullBuyBtnPng
                }
                alt=''
              />
            </SoundButton>
            <SoundButton
              className='flex-1'
              onClick={baedanggumInfoModal.open}
              disabled={item.baedanggum === true}
              playSound={shouldPlaySound}
            >
              <img
                src={
                  item.baedanggum === true
                    ? BaedangumBtnDisabledPng
                    : BaedangumBtnPng
                }
                alt=''
              />
            </SoundButton>
          </div>
          <div className='flex space-x-2'>
            <SoundButton
              className='flex-1'
              onClick={getInfoInfoModal.open}
              disabled={item.get_info === true}
              playSound={shouldPlaySound}
            >
              <img
                src={
                  item.get_info === true ? GetInfoBtnDisabledPng : GetInfoBtnPng
                }
                alt=''
              />
            </SoundButton>
            <SoundButton
              className='flex-1'
              onClick={loanInfoModal.open}
              disabled={item.loan === true}
              playSound={shouldPlaySound}
            >
              <img
                src={item.loan === true ? LoanBtnDisabledPng : LoanBtnPng}
                alt=''
              />
            </SoundButton>
          </div>
        </div>
      </div>
    </div>
  );
};
