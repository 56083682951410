import { FC, Fragment, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
interface PresenterChoiceMobileModalProps {
  isOpen: boolean;
  select: string;
  name: string;
  onClose?: () => void;
}

export const PresenterChoiceMobileModal: FC<
  PresenterChoiceMobileModalProps
> = ({
  isOpen,
  onClose = () => {
    console.warn('please write onClose');
  },
  name,
  select,
}) => {
  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        onClose?.();
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[0.85]"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{
                  maxWidth: 360,
                  width: 360,
                  maxHeight: 640,
                  height: 640,
                  padding: '59px 50px',
                }}
              >
                <Dialog.Panel className="w-full h-full">
                  <p className="text-p2 text-white text-center font-medium mt-[168px]">
                    <div style={{ fontSize: 35 / 2, lineHeight: 0 }}>
                      이번 의사결정권자
                    </div>
                    <strong className="text-p5">{name}</strong>
                    <span style={{ fontSize: 35 / 2, lineHeight: 0 }}>
                      님의 선택은
                    </span>
                  </p>
                  <div
                    className="mx-auto rounded-full text-center"
                    style={{
                      width: 176 / 2,
                      height: 176 / 2,
                      backgroundColor: '#ffed10',
                      marginTop: 37 / 2,
                      boxShadow: '0 0 10px 0 rgba(255, 237, 16, 0.7)',
                      padding: 33 / 2,
                    }}
                  >
                    <span
                      style={{ fontSize: 110 / 2 }}
                      className="font-bold leading-none text-darkBlueGray"
                    >
                      {select}
                    </span>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
