import { FC } from 'react';
import { GameStep } from '../../../../../constants/step-enum';
import { StepItem } from '../../../../../components/StepItem';
import { Timer } from '../../../../components/Timer';

interface Round2StepHeaderProps {
  step: GameStep;
  expiryTimestamp?: string;
}

function StepToStr(step: GameStep) {
  switch (step) {
    case GameStep.STOCK_DASHBOARD:
      return '주가현황 확인 단계';
    case GameStep.FINAL_DECISION:
      return '의사 결정 단계';
    case GameStep.TEAM_PRESENTATION:
      return '팀별 발언 단계';
    case GameStep.STOCK_DEAL:
      return '주식 매매 단계';
    default:
      return '알 수 없는 단계';
  }
}

export const Round2StepHeader: FC<Round2StepHeaderProps> = ({
  step,
  expiryTimestamp,
}) => {
  return (
    <div className="flex" style={{ padding: '0 14px', marginBottom: 14 }}>
      <div className="flex-1">
        <p
          className="text-white font-medium text-p8"
          style={{ lineHeight: 1, marginBottom: 18 }}
        >
          현재 단계: {StepToStr(step)} 진행중
        </p>
        <div className="flex space-x-[21px]">
          <StepItem active={step === GameStep.STOCK_DASHBOARD}>
            주가 현황
          </StepItem>
          <StepItem active={step === GameStep.FINAL_DECISION}>
            의사 결정
          </StepItem>
          <StepItem active={step === GameStep.TEAM_PRESENTATION}>
            팀별 발언
          </StepItem>
          <StepItem active={step === GameStep.STOCK_DEAL} hasArrow={false}>
            주식 매매
          </StepItem>
        </div>
      </div>
      <div style={{ width: 158 }}>
        <p className="text-white font-medium text-p8" style={{ lineHeight: 1 }}>
          남은 시간
        </p>
        {expiryTimestamp ? (
          <Timer expiryTimestamp={expiryTimestamp} onExpire={() => {}} />
        ) : (
          <p className="font-digit font-bold text-p1 text-white">--:--</p>
        )}
      </div>
    </div>
  );
};
