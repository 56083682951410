import { PlaySessionType } from '../types/PlaySession.type';

export function getTotalCash(userId: string, session: PlaySessionType) {
  const stockInfos = session.stocks;
  const users = session.users;
  const currStockPriceMap = stockInfos.reduce((acc, v) => {
    const { _id, price_info: _price_info } = v;
    const price_info = (_price_info ?? []).filter((v, _, arr) => {
      const result = !(arr.length >= 3 && v.turn === 0);
      return result;
    });
    const recentlyPriceInfo = price_info[price_info.length - 1];
    acc[_id] = recentlyPriceInfo?.price ?? 0;
    return acc;
  }, {} as Record<string, number>);
  const user = users.find((v) => v._id === userId);
  const userStockInfo = user?.stock_info ?? [];
  const curr_userStock = userStockInfo[userStockInfo.length - 1]?.stocks ?? [];

  const prevStockPriceMap = stockInfos.reduce((acc, v) => {
    const { _id, price_info: _price_info } = v;
    const price_info = (_price_info ?? []).filter((v, _, arr) => {
      const result = !(arr.length >= 3 && v.turn === 0);
      return result;
    });
    const recentlyPriceInfo = price_info[price_info.length - 2];
    acc[_id] = recentlyPriceInfo?.price ?? 0;
    return acc;
  }, {} as Record<string, number>);
  const turn_cash = user?.turn_cash ?? [];
  const current_cash = turn_cash[turn_cash.length - 1]?.cash ?? 0;
  const prev_cash =
    turn_cash[turn_cash.length - 3]?.cash ??
    turn_cash[turn_cash.length - 2]?.cash ??
    0;

  const stocks = curr_userStock.map((stock) => {
    const price = currStockPriceMap[stock._id] ?? 0;
    const amount = stock.amount;
    const value = price * amount;
    const prevPrice = prevStockPriceMap[stock._id] ?? 0;
    const prevValue = amount * prevPrice;
    const team = session.teams.find((team) => team._id === stock._id);
    const name = team?.name ?? 'NONE';
    const code = team?.code ?? '';
    return {
      _id: stock._id,
      name,
      code: code,
      price: price,
      amount: amount,
      value: value,
      prevValue,
      rate: prevValue === 0 ? 0 : ((value - prevValue) / prevValue) * 100,
    };
  });
  const current_stockValue = stocks.reduce((acc, v) => {
    acc += v.value;
    return acc;
  }, 0);
  const prevStocks =
    userStockInfo[userStockInfo.length - 3]?.stocks ??
    userStockInfo[userStockInfo.length - 2]?.stocks ??
    [];
  const prev_stockValue = prevStocks.reduce((acc, v) => {
    acc += v.amount * (prevStockPriceMap[v._id] ?? 0);
    return acc;
  }, 0);
  const start_cash = session.balance.base_cash;
  const start_stockValue =
    session.balance.base_stock * session.balance.start_stock_price;

  return {
    current_cash,
    current_stockValue: current_stockValue,
    stocks: stocks,
    prev_cash,
    prev_stockValue,
    start_cash: isNaN(start_cash) ? 0 : start_cash,
    start_stockValue: isNaN(start_stockValue) ? 0 : start_stockValue,
  };
}
