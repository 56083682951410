import { FC, useState } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../utils/getMyTeam';
import { getPresenter } from '../../../../utils/getPresenterName';
import {
  DeicisionIconPng,
  RightArrowDarkBluePng,
} from '../../../../assets/images';
import { getCurrentTeamDecision } from '../../../../utils/getCurrentTeamDecision';
import classNames from 'classnames';
import { BottonSheetModal } from '../components/BottonSheetModal';
import { GameApis } from '../../../../apis/game.apis';
import { HttpError } from '../../../../apis/http-error';
import { BackButton } from '../components/BackButton';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';
import { GameStep } from '../../../../constants/step-enum';
import { PresenterChangeChoiceMobileModal } from '../components/PresenterChangeChoiceMobileModal';
import { SoundButton } from '../../../../components/SoundButton';

interface FinalDecisionProps {}

const PresenterSelectScreen: FC<{
  initSelect: string;
  onClose?: () => void;
}> = ({ initSelect, onClose }) => {
  const theme = useThemeContext();
  const [currentSelect, setCurrentSelect] = useState(initSelect);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const { session, userId } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const selectedColor = theme.number_color_selected;
  const submitBackgroundColor = theme.modal_submit_answer_box_color;
  const answerBoxBackground = theme.submit_answer_box_color;
  const handleSubmit = async () => {
    try {
      if (!userId) {
        throw new Error('사용자 정보가 존재하지 않습니다.');
      }
      await GameApis.selectFinalDecision({
        answer: currentSelect,
        session_id: session._id,
        userId,
      });
      onClose?.();
    } catch (e) {
      const err = e as any as HttpError;
      alert(err.message);
      throw e;
    }
  };

  return (
    <div style={{ padding: '26.5px 20px' }}>
      <BottonSheetModal
        isOpen={openSelectModal}
        onClose={() => setOpenSelectModal(false)}
        onSubmit={handleSubmit}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div style={{ padding: 40, paddingBottom: 2.5 }}>
          <p className='text-center text-darkBlueGray text-m5'>
            선택하신 보기로
            <br />
            <strong>정답 제출</strong>하시겠습니까?
          </p>
        </div>
      </BottonSheetModal>
      <BackButton onClick={onClose} />
      <h1
        className='text-center text-m5 font-medium text-darkBlueGray'
        style={{ marginTop: 77, marginBottom: 37.5 }}
      >
        <strong>선택 변경 할 보기</strong>를
        <br />
        선택해 주세요
      </h1>
      <div className='space-x-2.5 flex'>
        <SoundButton
          type='button'
          className='flex-1 aspect-square rounded-full pt-3'
          style={{
            borderWidth: 2.5,
            borderColor: currentSelect === '1' ? selectedColor : '#172547',
            backgroundColor: currentSelect === '1' ? selectedColor : undefined,
          }}
          onClick={() => setCurrentSelect('1')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '1',
              'text-white': currentSelect === '1',
              'font-medium': currentSelect !== '1',
              'font-bold': currentSelect === '1',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            1
          </span>
        </SoundButton>
        <SoundButton
          type='button'
          className='flex-1 aspect-square rounded-full pt-3'
          style={{
            borderWidth: 2.5,
            borderColor: currentSelect === '2' ? selectedColor : '#172547',
            backgroundColor: currentSelect === '2' ? selectedColor : undefined,
          }}
          onClick={() => setCurrentSelect('2')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '2',
              'text-white': currentSelect === '2',
              'font-medium': currentSelect !== '2',
              'font-bold': currentSelect === '2',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            2
          </span>
        </SoundButton>
        <SoundButton
          type='button'
          className='flex-1 aspect-square rounded-full pt-3'
          style={{
            borderWidth: 2.5,
            borderColor: currentSelect === '3' ? selectedColor : '#172547',
            backgroundColor: currentSelect === '3' ? selectedColor : undefined,
          }}
          onClick={() => setCurrentSelect('3')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '3',
              'text-white': currentSelect === '3',
              'font-medium': currentSelect !== '3',
              'font-bold': currentSelect === '3',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            3
          </span>
        </SoundButton>
      </div>
      <SoundButton
        style={{ marginTop: 135, backgroundColor: answerBoxBackground }}
        className='blue-btn-mo w-full'
        disabled={currentSelect === ''}
        type='button'
        onClick={() => setOpenSelectModal(true)}
        playSound={shouldPlaySound}
      >
        정답 제출
      </SoundButton>
    </div>
  );
};

const PresenterScreen: FC<{
  presenter: { _id: string; name: string; select_final_choice: boolean };
}> = ({ presenter }) => {
  const theme = useThemeContext();
  const [mode, setMode] = useState('view');
  const { session, teamCode, userId } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const openEditMode = () => {
    setMode('select');
  };
  const myTeam = getMyTeam(teamCode, session.teams);
  const { final, first } = getCurrentTeamDecision(myTeam?._id ?? '', session);
  const changeColor = theme.number_color_change;
  const changeAnswerBox = theme.change_answer_box_color;
  const isPresenter = presenter?._id === userId ?? '';
  if (presenter.select_final_choice === true) {
    return (
      <div style={{ padding: '110px 50px 20px' }}>
        <h1
          className='text-center font-medium text-m5 text-darkBlueGray'
          style={{ lineHeight: 1.5 }}
        >
          <strong>최종 결정 단계 진행 중</strong>입니다
          <br />
          잠시만 기다려 주세요
        </h1>
        <img
          src={DeicisionIconPng}
          alt='최종 결정 아이콘'
          style={{ width: 242 / 2, height: 300 / 2, marginTop: 75 / 2 }}
          className='mx-auto'
        />
        {!!presenter && first !== final && isPresenter ? (
          <PresenterChangeChoiceMobileModal
            isRightStep={session.current_step === GameStep.FINAL_DECISION}
            finalDecisionPenaltyPrice={session.balance.final_decision_penalty_price.toLocaleString(
              'ko-KR'
            )}
          />
        ) : null}
      </div>
    );
  }
  if (mode === 'select') {
    return (
      <PresenterSelectScreen
        initSelect={final}
        onClose={() => setMode('view')}
      />
    );
  }
  return (
    <div
      className='flex-1 overflow-y-auto'
      style={{ padding: '130px 20px 20px ' }}
    >
      <div className='flex justify-center space-x-[20px] items-end'>
        <div style={{ width: 186 / 2 }}>
          <p className='text-center text-m5 font-medium text-darkBlueGray mb-12'>
            1차 선택
          </p>
          <div
            style={{
              width: 186 / 2,
              height: 186 / 2,
              borderWidth: 2.5,
              backgroundColor: changeColor,
              borderColor: changeColor,
            }}
            className='bg-darkBlueGray rounded-full flex justify-center items-center pt-2'
          >
            <span className='text-white text-p2 font-bold'>{first}</span>
          </div>
        </div>
        <div className='pb-9'>
          <img
            src={RightArrowDarkBluePng}
            alt='right arrow'
            style={{ width: 53 / 2, height: 45 / 2 }}
          />
        </div>
        <div style={{ width: 186 / 2 }}>
          <p className='text-center text-m5 font-medium text-darkBlueGray mb-12'>
            최종 선택
          </p>
          <div
            style={{
              width: 186 / 2,
              height: 186 / 2,
              borderWidth: 2.5,
              backgroundColor: changeColor,
              borderColor: changeColor,
            }}
            className='bg-darkBlueGray rounded-full flex justify-center items-center pt-2'
          >
            <span className='text-white text-p2 font-bold'>{final}</span>
          </div>
        </div>
      </div>
      <p
        className='pl-10 text-coral text-m7 font-medium leading-snug'
        style={{ marginTop: 184 / 2 }}
      >
        ※ 1차 선택과 최종 선택이 다른 경우
        <br />
        보유 현금의{' '}
        {session.balance.final_decision_penalty_price.toLocaleString('ko-KR')}
        원이 차감 됩니다.
      </p>
      <SoundButton
        className='blue-btn-mo w-full mt-6'
        type='button'
        onClick={openEditMode}
        style={{
          backgroundColor: changeAnswerBox,
        }}
        playSound={shouldPlaySound}
      >
        최종 선택하기
      </SoundButton>
    </div>
  );
};

export const FinalDecision: FC<FinalDecisionProps> = () => {
  const { session, teamCode, userId } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const presenter = getPresenter(myTeam?._id ?? '', session);
  const isPresenter = presenter?._id === userId ?? '';
  const presenterName = presenter?.name ?? 'NONE';

  if (!!presenter && isPresenter === true) {
    return <PresenterScreen presenter={presenter} />;
  }
  return (
    <div className='flex-1 overflow-y-auto' style={{ padding: '110px 20px' }}>
      <h1
        className='text-center font-medium text-m5 text-darkBlueGray'
        style={{ lineHeight: 1.5 }}
      >
        <strong>{presenterName}</strong> 님의 최종 결정을
        <br />
        기다려 주세요
      </h1>
      <img
        src={DeicisionIconPng}
        alt='최종 결정 아이콘'
        style={{ width: 242 / 2, height: 300 / 2, marginTop: 75 / 2 }}
        className='mx-auto'
      />
    </div>
  );
};
