import { FC, FormEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../contexts/theme/useThemeContext';
import { InputBox } from '../../components/InputBox';
import { Button } from '../../components/Button';
import { HttpError } from '../../apis/http-error';
import { GameApis } from '../../apis/game.apis';

interface IndexPageProps {}

export const IndexPage: FC<IndexPageProps> = () => {
  const [accessCode, setAccessCode] = useState('');
  const theme = useThemeContext();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e?.stopPropagation) {
      e.stopPropagation();
    }
    setIsSubmitting(true);
    try {
      const res = await GameApis.joinTeam(accessCode);
      navigate('/game', {
        state: { session: res, teamCode: accessCode },
      });
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      className="w-full h-full overflow-y-auto bg-theme p-10"
      onSubmit={handleSubmit}
    >
      <div className="flex min-h-full justify-center items-center flex-col">
        {theme.logo ? (
          <img
            alt="logo"
            src={theme.logo}
            className="w-full object-contain"
            style={{ maxWidth: 700, marginBottom: 85 }}
          />
        ) : null}
        <div style={{ maxWidth: 500 }} className="w-full">
          <p
            className="text-white"
            style={{ marginBottom: 15, fontSize: 28, lineHeight: '47px' }}
          >
            접속번호
          </p>
          <InputBox
            placeholder="접속번호를 입력해주세요"
            className="w-full mb-5"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value.trim())}
            maxLength={7}
          />
          <Button
            type="submit"
            className="w-full"
            disabled={accessCode.length !== 7 || isSubmitting === true}
          >
            입장
          </Button>
        </div>
      </div>
    </form>
  );
};
