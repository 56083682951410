import { FC, useEffect, useState } from 'react';
import { HttpClient } from './apis/http-client';
import { BASE_URL } from './constants/baseUrl';
import { getDevice } from './utils/getDevice';
import { PcApp } from './PC/PcApp';
import { MoApp } from './MO/MoApp';
import { ThemeProvider } from './contexts/theme/provider';

interface AppProps {}

const device = getDevice();

export const App: FC<AppProps> = () => {
  const [clientId, setClientId] = useState<string | null>(null);
  const AppComponent = device === 'PC' ? PcApp : MoApp;
  useEffect(() => {
    let mounted = true;
    const _bootstrap = async () => {
      let path = window.location.pathname;
      if (path.startsWith('/')) {
        path = path.replace('/', '');
      }
      const [id = ''] = path.split('/');
      if (id) {
        const isValid = await HttpClient.get(`${BASE_URL}/apis/c/${id}/valid`)
          .then((res) => res.data)
          .catch(() => false);

        if (isValid === false && mounted === true) {
          setClientId('');
          return;
        }
      }
      if (mounted === true) {
        setClientId(id);
        HttpClient.defaults.baseURL = `${BASE_URL}/apis/c/${id}`;
      }
    };
    _bootstrap();
    return () => {
      mounted = false;
      delete HttpClient.defaults.baseURL;
    };
  }, []);

  if (clientId === null) {
    return null; // LOADING
  }

  if (clientId === '') {
    return (
      <div className="w-full h-full p-5 flex justify-center items-center bg-darkBlueGray">
        <p
          className="text-white font-medium text-m1 text-center"
          style={{ lineHeight: 1.5 }}
        >
          잘못된 접근입니다
          <br />
          정확한 URL을 입력해 주세요
        </p>
      </div>
    ); // 잘못된 접근
  }

  return (
    <ThemeProvider>
      <AppComponent clientId={clientId} />
    </ThemeProvider>
  );
};
