import { FC } from 'react';
import { SoundButton } from '../../../../../components/SoundButton';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';

interface MenuProps {
  onSelectBuy?: () => void;
  onSelectSell?: () => void;
}

export const Menu: FC<MenuProps> = ({ onSelectBuy, onSelectSell }) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  return (
    <div className='flex-1 overflow-y-auto' style={{ padding: 20 }}>
      <p
        className='text-center text-darkBlueGray text-m5'
        style={{ marginTop: 91, marginBottom: 30, lineHeight: 1.5 }}
      >
        <strong>매도 또는 매수</strong>를
        <br />
        선택해 주세요
      </p>
      <div className='flex space-x-2.5'>
        <SoundButton
          className='mo-btn-stock-buy flex-1'
          onClick={onSelectBuy}
          playSound={shouldPlaySound}
        >
          매수
          <br />
          <span>(사기)</span>
        </SoundButton>
        <SoundButton
          className='mo-btn-stock-sell flex-1'
          onClick={onSelectSell}
          playSound={shouldPlaySound}
        >
          매도
          <br />
          <span>(팔기)</span>
        </SoundButton>
      </div>
    </div>
  );
};
