import { FC, Fragment } from "react";
import { StockDealRecepit } from "../../../../apis/game.apis";
import { Dialog, Transition } from "@headlessui/react";
import { MyCashBoxPng } from "../../../../assets/images";
import classNames from "classnames";
import { getFormattedPrice } from "../../../../utils/getFormattedDiff";
import { SoundButton } from "../../../../components/SoundButton";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";

interface StockRecepitModalProps {
  data: StockDealRecepit;
  isOpen?: boolean;
  onClose?: () => void;
}

export const StockRecepitModal: FC<StockRecepitModalProps> = ({
  data,
  isOpen = false,
  onClose = () => {},
}) => {
  const isStockBuy = data.stock.diff >= 0;
  const label = isStockBuy ? "매수(사기)" : "매도(팔기)";
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[0.43]"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="min-h-full" style={{ padding: "140px 12.5px 0" }}>
              <div className="h-full">
                <div
                  className="rounded-t-lg bg-white h-full"
                  style={{ padding: "50px 19px 0" }}
                >
                  <div className="flex justify-center">
                    <div className="border-b border-browngrey border-dashed pb-4 mb-4">
                      <div className="border-b border-browngrey border-dashed mx-4">
                        <p
                          className="px-1 pt-1 text-m5 text-darkBlueGray mt-0.5"
                          style={{
                            backgroundColor: isStockBuy ? "#ffebeb" : "#edf2ff",
                          }}
                        >
                          <span
                            className={classNames({
                              "text-coral": isStockBuy,
                              "text-dodgerBlue": isStockBuy === false,
                            })}
                          >
                            {label}
                          </span>
                          가 완료되었습니다
                        </p>
                        <div className="border-b border-browngrey border-dashed my-1"></div>
                      </div>
                      <div className="flex justify-between items-center mt-16">
                        <p className="text-m6 text-browngrey font-medium">
                          현금 보유량
                        </p>
                        <p className="text-m4 font-medium">
                          <strong>
                            {data.cash.current.toLocaleString("ko-KR")}
                          </strong>
                          <span className="text-m6"> 원</span>
                        </p>
                      </div>
                      <div className="flex justify-end">
                        <p
                          className={classNames("text-m7  mt-3", {
                            "text-coral": data.cash.diff >= 0,
                            "text-dodgerBlue": data.cash.diff < 0,
                          })}
                        >
                          {getFormattedPrice({
                            price: data.cash.diff,
                            prefixSpace: false,
                            addSymbol: true,
                          })}
                          원
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    <div
                      className="border-b border-browngrey border-dashed"
                      style={{ paddingBottom: 12 }}
                    >
                      <p className="text-m6 mb-2 text-browngrey font-medium">
                        주식 보유량
                      </p>
                      <div className="flex justify-between items-center">
                        <p className="text-m6 text-darkBlueGray font-medium">
                          {data.stock.name}팀
                        </p>
                        <p className="text-m6">
                          <strong className="text-m4">
                            {data.stock.amount.toLocaleString("ko-KR")}
                          </strong>{" "}
                          주
                        </p>
                      </div>
                      <div className="flex justify-end">
                        <p
                          className={classNames("text-m7  mt-4", {
                            "text-coral": data.stock.diff >= 0,
                            "text-dodgerBlue": data.stock.diff < 0,
                          })}
                        >
                          {getFormattedPrice({
                            price: data.stock.diff,
                            prefixSpace: false,
                            addSymbol: true,
                          })}
                          주
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pb-3">
                    <SoundButton
                      className={classNames("w-full mt-16 text-white", {
                        "red-btn-mo": isStockBuy === true,
                        "dodgerblue-btn-mo": isStockBuy === false,
                      })}
                      onClick={onClose}
                      playSound={shouldPlaySound}
                    >
                      닫기
                    </SoundButton>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    marginTop: -1,
                    backgroundImage: `url(${MyCashBoxPng})`,
                  }}
                  className="bg-bottom bg-cover"
                ></div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
