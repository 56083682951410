import { createContext } from 'react';
import { ThemeType } from '../../types/ThemeType';
export const ThemeContext = createContext<ThemeType>({
  background_color: '#172547',
  button_color: '#cba665',
  button_font_color: '#172547',
  logo: null,
  //선택된 번호 버튼 색상
  number_color_selected: '#172547',
  //선택 변경 번호 버튼 색상
  number_color_change: '#172547',
  //정답 제출 박스 색상
  submit_answer_box_color: '#b5b3f8',
  //선택 변경 박스 색상
  change_answer_box_color: '#b5b3f8',
  //정답 제출 박스 색상
  modal_submit_answer_box_color: '#b5b3f8',
  //우리팀 폰트 색상
  our_team_color: '#b5b3f8',
  //주가 현황 우리팀 폰트 색상
  stock_status_our_team_color: '#8a86ff',
  //주가 현황 우리팀 보더 색상
  stock_status_our_team_border: '#cccbff',
  //보유 주식 박스 색상
  my_stock_box_color: '#b5b3f8',
  //보유 주식 박스 컨텐트 색상
  my_stock_box_content_color: '#f4f4ff',
  //아이템 사용 박스 색상
  use_item_box_color: '#b5b3f8',
  //PC 선택 하신 보기로 정답 제출 하시겠습니까? 팝업 보더
  pc_popup_border: '#b5b3f8',
  //PC 선택 하신 보기로 정답 제출 하시겠습니까? 팝업 제출버튼
  pc_popup_submit_button: '#b5b3f8',
  //PC 헤더 박스 내 단계 버튼 색상
  pc_header_step_color: '#b5b3f8',
  //PC 사례 폰트 색상
  pc_case_index_color: '#b5b3f8',
  //현황판에서 step 폰트 색상
  dashboard_step_color: '#b5b3f8',
  //PC 주가 현황에서 팀 폰트 색상
  pc_stock_status_team_color: '#4b7fff',
});
