import axios from 'axios';

export class HttpError extends Error {
  raw?: Error;
  constructor(message: string, raw?: Error) {
    super(message);
    this.raw = raw;
  }
}

export function ErrorToHttpError(e: Error) {
  if (axios.isAxiosError(e)) {
    const data = e.response?.data;
    if (typeof data?.message === 'string') {
      return new HttpError(data.message, e);
    } else if (e.response?.status === 404) {
      return new HttpError('API를 찾을 수 없습니다.', e);
    } else if (e.response?.status === 400) {
      return new HttpError('올바른 파라미터를 입력해 주세요.', e);
    } else if (typeof e.response === 'undefined') {
      return new HttpError('네트워크 연결에 실패하였습니다.', e);
    } else {
      return new HttpError('알려지지 않은 오류가 발생하였습니다.', e);
    }
  } else {
    return new HttpError('네트워크 오류가 발생하였습니다.', e);
  }
}
