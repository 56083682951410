import { FC } from 'react';

interface ResultPageProps {}

export const ResultPage: FC<ResultPageProps> = () => {
  return (
    <div className="w-full h-full flex items-center justify-center flex-col bg-theme">
      <h1 className="text-p3 text-white text-center">
        교육과정이 종료되었습니다
      </h1>
    </div>
  );
};
