import { FC, useState, useEffect } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import classNames from 'classnames';
import { StockCharts } from '../components/StockChart';
import { StockChangeModal } from '../components/StockChangeModal';
import { getFormattedDiff } from '../../../../utils/getFormattedDiff';
import { getStockInfo } from '../../../../utils/getStockInfo';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';

interface Round2StockDashBoardProps {}

const AnswerMap: Record<string, string> = {
  '1': 'first_answer_description',
  '2': 'second_answer_description',
  '3': 'third_answer_description',
};

export const Round2StockDashBoard: FC<Round2StockDashBoardProps> = () => {
  const [open, setOpen] = useState(false);
  const theme = useThemeContext();
  const { session, teamCode } = usePlaySessionContext();
  const team = session.teams.find((v) => v.code === teamCode);

  const teamName = team?.name ?? 'NONE';
  const { prev_price, price } = getStockInfo(
    team?._id ?? 'none',
    session.stocks,
  );
  const currentPrice = price;
  const prevPrice = prev_price;
  const diffPrice = currentPrice - prevPrice;
  const _diffPercent = (diffPrice / prevPrice) * 100;
  const diffPercent = prevPrice === 0 ? 0 : _diffPercent;
  const prev_scenario = session.gameScenarios.find(
    (v) => v._id === session.turn_info[session.turn_info.length - 2]?.scenario,
  );
  const select_team_answer =
    session.turn_info[session.turn_info.length - 2]?.teams?.find(
      (v) => v._id === team?._id,
    )?.final_choice ?? '';

  const stockHistory = session.stocks.reduce((acc, v) => {
    const team = session.teams.find((item) => item._id === v._id);
    if (team?.name === undefined) {
      return acc;
    }
    let gap = 0;
    for (let i = 0; i < v.price_info.length; i++) {
      if (v.price_info.length >= 3 && v.price_info[i].turn === 0) {
        gap++;
        continue;
      }
      if (acc[i - gap] === undefined) {
        acc[i - gap] = {};
      }
      acc[i - gap][team.name] = v.price_info[i].price;
    }
    return acc;
  }, [] as { [x: string]: number }[]);

  useEffect(() => {
    if (session.current_turn !== 1 || session.current_round !== 2) {
      setOpen(true);
    }
  }, [session.current_round, session.current_turn]);

  const teamColor = theme.pc_stock_status_team_color;

  return (
    <div className="w-full relative">
      <StockChangeModal
        isOpen={open}
        diff={diffPrice}
        content={
          (prev_scenario as any)?.[AnswerMap[select_team_answer ?? 'NONE']] ??
          '답변을 선택하지 않으셨습니다.'
        }
        onClose={() => setOpen(false)}
      />
      <p
        className="text-p4 text-white font-medium  mb-4"
        style={{ marginLeft: 77 }}
      >
        <strong
          style={{
            color: teamColor,
          }}
        >
          {teamName}팀
        </strong>{' '}
        현재 주가
      </p>
      <div
        className="flex items-end flex-col"
        style={{ marginLeft: 77, width: 344, marginBottom: 23 }}
      >
        <p className="text-white font-medium  mb-2.5" style={{ fontSize: 34 }}>
          <strong style={{ fontSize: 50 }}>
            {currentPrice.toLocaleString('ko-kr')}
          </strong>
          원
        </p>
        <p
          className={classNames('font-medium text-p4 ', {
            'text-coral': diffPrice >= 0,
            'text-dodgerBlue': diffPercent < 0,
          })}
        >
          {getFormattedDiff({
            price: diffPrice,
            rate: diffPercent,
            prefixSpace: false,
          })}
        </p>
      </div>
      <div style={{ height: 510 }}>
        <StockCharts data={stockHistory} myTeamId={teamName} />
      </div>
    </div>
  );
};
