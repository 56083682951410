import { FC } from 'react';
import { FreeDiscussionIconPng } from '../../../../assets/images';

interface TeamPresentationProps {}

export const TeamPresentation: FC<TeamPresentationProps> = () => {
  return (
    <div className="flex-1 overflow-y-auto p-5 pt-0">
      <div>
        <p
          className="text-center text-darkBlueGray text-m5 leading-normal"
          style={{ marginBottom: 46, paddingTop: 125 }}
        >
          <strong>팀별 발언</strong> 시간입니다
        </p>
        <img
          src={FreeDiscussionIconPng}
          style={{ width: 315 / 2, height: 272 / 2 }}
          alt="의사 결정"
          className="block mx-auto"
        />
      </div>
    </div>
  );
};
