import { PlaySessionType } from '../types/PlaySession.type';

export function getStockInfo(
  stockId: string,
  stocks: PlaySessionType['stocks']
) {
  const stockInfo = stocks.find((v) => v._id === stockId);
  const stockPrice = (stockInfo?.price_info ?? []).filter((v, _, arr) => {
    const result = !(arr.length >= 3 && v.turn === 0);
    return result;
  });
  const currentPrice = stockPrice[stockPrice.length - 1]?.price ?? 0;
  const prevPrice = stockPrice[stockPrice.length - 2]?.price ?? 0;

  return {
    name: stockInfo?.name ?? 'NONE',
    price: currentPrice,
    prev_price: prevPrice,
  };
}
